<template>
  <div></div>
</template>
<script>
export default {
  name: "DocumentList",
  mounted() {
    this.$router.push({
      name: "PageUnderConstruction",
      params: { key: "document" },
    });
  },
};
</script>
